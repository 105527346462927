.center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin: auto;
}

.container {
  margin: 0 2rem;
  display: grid;
  grid-template-rows: repeat(2, 1fr);
}

.message_wrapper {
  display: flex;
  align-items: center;
}

.code {
  border-right: 1px solid var(--text-color);
  margin: 0;
  margin-right: 2rem;
  padding-right: 2rem;
  font-weight: var(--font-medium);
  vertical-align: top;
}

.button_container {
  margin-top: 1rem;
  justify-self: center;
}

@media screen and (min-width: 410px) {
  .center {
    min-width: 400px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
